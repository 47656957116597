import { useController } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { CommunityMember } from '@zealy/queries';
import { TaskCard } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';
import { truncateAddress } from '@zealy/utils';

import { TaskBadge } from '#components/TaskBadge';
import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useCommunityV2 } from '#hooks/useCommunityV2';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'onChain' }> & { isPreview?: boolean };

export const OnChain = ({
  task,
  error,
}: {
  task: WrapperProps;
  error?: { message: string; type: string };
}) => {
  const t = useTranslations('quest.type.api');
  const user = useAuthenticatedUser<CommunityMember>();
  const community = useCommunityV2();

  const userAddress = user.data?.verifiedAddresses?.[task.settings.network];

  return (
    <TaskCard type="onChain" title={t('label')} icon={TASK_CONFIG.onChain.icon}>
      <div className="p-300 flex flex-col gap-100">
        {task.settings.title && (
          <p className="body-paragraph-xl-bold text-primary">{task.settings.title}</p>
        )}
        {task.settings.description && (
          <p className="body-paragraph-lg text-secondary">{task.settings.description}</p>
        )}
        {userAddress ? (
          <>
            <p className="body-paragraph-lg text-secondary">
              {t.rich('descriptionAccounts', {
                b: children => <b className="body-paragraph-lg-bold text-primary">{children}</b>,
                accounts: userAddress,
                accountLength: 1,
              })}
            </p>
            <span className="gap-100 items-center inline-flex py-50 px-25">
              <TaskBadge type="token" size="xxs" />
              <b className="body-paragraph-lg-bold text-primary">{truncateAddress(userAddress)}</b>
            </span>
          </>
        ) : user.isFetched ? (
          <p className="body-paragraph-lg text-secondary">{t('descriptionMissingAccounts')}</p>
        ) : null}
        {error && (
          <>
            <p className="body-component-md text-error-primary">{error?.message}</p>
            <p className="body-component-md font-mono text-secondary">
              {t('debug', {
                requestId: error?.type,
                communityName: community.data?.name,
              })}
            </p>
          </>
        )}
      </div>
    </TaskCard>
  );
};

const OnChainClaim = withQuest<WrapperProps, 'onChain'>(({ task }) => {
  const { formState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: task.type,
    },
  });

  const error = formState.errors?.[task.id] as { message: string; type: string } | undefined;

  return <OnChain task={task} error={error} />;
});

export default OnChainClaim;
