import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { CommunityMember } from '@zealy/queries';
import { IconButton, TaskCard, TooltipProvider } from '@zealy/design-system';
import { FingerprintLine, RefreshCcwLine } from '@zealy/icons';
import { useAuthenticatedUser, useRedirectUrlApiQuestTask } from '@zealy/queries';
import { truncateAddress } from '@zealy/utils';

import type { TaskBadgeProps } from '#components/TaskBadge';
import { TaskBadge } from '#components/TaskBadge';
import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useCommunityV2 } from '#hooks/useCommunityV2';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';
import { useZealyConnectStatusForQuestAndTask } from '../Tasks.hooks';

type WrapperProps = Extract<QuestTask, { type: 'api' }> & { questId: string };

const formatSentence = (accounts: string[]) => {
  if (accounts.length === 1) return accounts[0];

  return accounts.slice(0, -1).join(', ') + ' or ' + accounts.slice(-1);
};

const getPosition = (index: number, length: number) => {
  if (index === 0) return 'first';
  if (index === length - 1) return 'last';
  return 'middle';
};

const ApiWrapper = withQuest<WrapperProps, 'api'>(({ task, questId }) => {
  const t = useTranslations('quest.type.api');
  const user = useAuthenticatedUser<CommunityMember>();
  const community = useCommunityV2();

  const { isConnected } = useZealyConnectStatusForQuestAndTask(questId, task);
  const generateRedirectLink = useRedirectUrlApiQuestTask({
    questId: questId,
    taskId: task.id,
  });

  const { formState } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type: task.type,
    },
  });

  const onReconnectClick = useCallback(() => {
    generateRedirectLink.mutate(undefined, {
      onSuccess: ({ redirectUrl }: { redirectUrl: string }) => {
        window.location.href = redirectUrl;
      },
    });
  }, [generateRedirectLink]);

  const accounts = useMemo(() => {
    return task.settings.identifications.reduce((acc, accountType) => {
      const accountFound = user?.data?.accounts?.find(
        account => account.accountType === accountType,
      );

      let accountValue: string | null | undefined;
      switch (accountType) {
        case 'discord': {
          accountValue = user?.data?.discordHandle;
          break;
        }
        case 'twitter': {
          if (accountFound?.tokenStatus === 'valid') {
            accountValue = `@${user?.data?.twitterUsername}`;
          }
          break;
        }
        case 'wallet': {
          if (task.settings.network) {
            accountValue = truncateAddress(user.data?.addresses?.[task.settings.network]);
          } else {
            accountValue = truncateAddress(user.data?.addresses?.['eth-mainnet']);
          }
          break;
        }
        case 'email': {
          accountValue = accountFound?.authentification;
          break;
        }
      }
      return accountValue ? { ...acc, [accountType]: accountValue } : acc;
    }, {} as Record<string, string>);
  }, [task, user]);

  const accountLength = Object.keys(accounts).length;

  const zealyConnected = isConnected(task.id);
  const error = formState.errors?.[task.id] as { message: string; type: string } | undefined;

  return (
    <TaskCard type="api" title={t('label')} icon={TASK_CONFIG.api.icon}>
      <div className="p-300 flex flex-col gap-100 text-primary">
        {task.settings.title && (
          <p className="body-paragraph-xl-bold text-primary">{task.settings.title}</p>
        )}
        {task.settings.description && (
          <p className="body-paragraph-lg text-secondary">{task.settings.description}</p>
        )}
        {accountLength ? (
          <>
            <p className="body-paragraph-lg text-secondary">
              {t.rich('descriptionAccounts', {
                b: children => <b className="body-paragraph-lg-bold text-primary">{children}</b>,
                accounts: formatSentence(Object.values(accounts)),
                accountLength,
              })}
            </p>
            <div>
              {Object.entries(accounts).map(([accountType, account], index) => (
                <span key={account} className="gap-100 items-center inline-flex py-50 px-25">
                  {t(`position.${getPosition(index, accountLength)}`)}
                  <TaskBadge
                    type={
                      (accountType === 'email'
                        ? 'invites'
                        : accountType === 'wallet'
                        ? 'token'
                        : accountType === 'twitter'
                        ? 'tweet'
                        : accountType) as TaskBadgeProps['type']
                    }
                    size="xxs"
                  />
                  <b className="body-paragraph-lg-bold text-primary">{account}</b>
                </span>
              ))}
            </div>
          </>
        ) : user.isFetched && task.settings.identifications.length && !zealyConnected ? (
          <p className="body-paragraph-lg text-secondary">{t('descriptionMissingAccounts')}</p>
        ) : null}

        {zealyConnected && (
          <div className="flex gap-50 items-center">
            <FingerprintLine className="icon-[--color-bg-task-type-api] w-icon-lg h-icon-lg" />
            <span className="body-component-lg text-secondary">{t('zealy-connect')}</span>
            <TooltipProvider>
              <IconButton
                size="sm"
                onlyIcon
                tooltip={t('reconnect')}
                variant="ghost"
                mutedText
                onClick={onReconnectClick}
                isDisabled={generateRedirectLink.isPending}
                icon={<RefreshCcwLine />}
              />
            </TooltipProvider>
          </div>
        )}
        {error && (
          <>
            <p className="body-component-md text-error-primary">{error?.message}</p>
            <p className="body-component-md font-mono text-secondary">
              {t('debug', {
                requestId: error?.type,
                communityName: community.data?.name,
              })}
            </p>
          </>
        )}
      </div>
    </TaskCard>
  );
});

export default ApiWrapper;
