import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import { Partnership } from '#components/Tasks/components/Partnership';
import { useCommunityV2 } from '#hooks/useCommunityV2';
import { useDebouncedValue } from '#hooks/useDebouncedValue';

import { useError } from '../../../../FormError.context';

export const PartnershipPreview = ({ index }: { index: number }) => {
  const t = useTranslations('quest.type.partnership');

  const task = useWatch({
    name: `tasks.${index}`,
  });

  const debouncedValue = useDebouncedValue(task?.settings?.subdomain as string, 500);

  const { setError, clearError } = useError(`tasks.${index}.settings.subdomain`);

  const { isLoading, data, status, isFetching, isFetched } = useCommunityV2(
    debouncedValue || '_',
    undefined,
    !!debouncedValue,
  );

  useEffect(() => {
    if (status === 'success') {
      clearError();
    } else if (status === 'error') setError(t('not-found'));

    return clearError;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, isLoading, isFetching, isFetched]);

  return <Partnership task={task} preview={true} />;
};
