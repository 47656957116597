import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';

import type { CommunityMember } from '@zealy/queries';
import { JoinTaskCard } from '@zealy/design-system';
import { useAuthenticatedUser, useJoinCommunity } from '@zealy/queries';

import { useError } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/FormError.context';
import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useCommunityV2 } from '#hooks/useCommunityV2';

import type { QuestTask } from '../Tasks.types';
import { withQuest } from '../Tasks.funcs';

type WrapperProps = Extract<QuestTask, { type: 'partnership' }>;

const type = 'partnership' as const;
type Task = typeof type;

export const Partnership = ({
  task,
  preview = false,
}: { task: WrapperProps } & { preview?: boolean }) => {
  const community = useCommunityV2(task.settings.subdomain);
  const join = useJoinCommunity(community.data?.subdomain ?? undefined);
  const user = useAuthenticatedUser<CommunityMember>(
    community.data?.subdomain ?? undefined,
    undefined,
    !!community.data?.subdomain,
  );

  const tCommon = useTranslations('common');
  const t = useTranslations('quest.type.partnership');
  const { formState } = useFormContext();

  const { clearError, error, setError } = useError(
    `${task.id}.role`,
    !preview && task.id && !user.data?.role ? 'Not member' : undefined,
  );

  useEffect(() => {
    if (!task.id) return;

    if (error && user.data?.role) {
      clearError();
    } else if (!error && !user.data?.role) {
      !preview && setError('Not member');
    }
  }, [task.id, clearError, error, setError, user.data?.role]);

  return (
    <JoinTaskCard
      type="partnership"
      title={t('label')}
      icon={TASK_CONFIG.partnership.icon}
      imageUrl={community.data?.image ?? undefined}
      name={community.data?.name}
      description={community.data?.description ?? undefined}
      buttonProps={{
        children: !user?.data?.role
          ? tCommon('join-community', {
              communityName: community.data?.name ?? tCommon('community'),
            })
          : tCommon('joined'),
        isDisabled: !user || !!user.data?.role,
        className:
          'w-full bg-task-type-partnership hover:bg-task-type-partnership-hover active:bg-task-type-partnership-press',
        onClick: () => join.mutate(),
      }}
      error={
        formState.errors?.[task.id]?.message
          ? String(formState.errors?.[task.id]?.message)
          : undefined
      }
    />
  );
};
const PartnershipClaim = withQuest<WrapperProps, Task>(({ task }) => {
  const { field } = useController({
    name: task.id,
    defaultValue: {
      taskId: task.id,
      type,
    },
  });

  return <Partnership task={task} />;
});

export default PartnershipClaim;
