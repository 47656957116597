import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { z } from 'zod';

import { Alert, Button } from '@zealy/design-system';
import { Loader2Line, RotateCwLine } from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';

import { envConfig } from '#app/config';
import { useCommunityV2 } from '#hooks/useCommunityV2';
import { cn } from '#utils/utils';

import type { APITask, TestResult } from './APITests.const';
import { TESTS } from './APITests.const';
import { ConfigureTestUser } from './ConfigureTestUser';

const isValidURL = (url: string) => z.string().url().safeParse(url).success;

export const APITests = ({
  fields,
  setError,
  runTests,
}: {
  fields: APITask['settings'];
  setError: (message?: string) => void;
  runTests: (
    fields: APITask['settings'],
    community: {
      subdomain: string;
      id: string;
    },
    questId?: string,
    userId?: string,
  ) => Promise<TestResult>;
}) => {
  const t = useTranslations('quest.type.api.tests');
  const [testIndex, setTestIndex] = useState(0);
  const [testResult, setTestResult] = React.useState<TestResult>();
  const startTime = useRef<null | number>(null);
  const community = useCommunityV2();
  const { questId } = useParams<{ questId: string }>();
  const { data: user } = useAuthenticatedUser();

  const updateLoadingMessage = useCallback(() => {
    setTestIndex(prevIndex => (prevIndex + 1) % TESTS.length);
  }, []);

  const run = useCallback(
    async (settings: APITask['settings']) => {
      if (!community.data) return;

      startTime.current = new Date().getTime();
      setTestResult(undefined);

      const result = await runTests(
        settings,
        community.data,
        questId !== 'new' ? questId : undefined,
        user?.id,
      );

      if (!result?.success) {
        setError(result?.reason ?? '');
      } else {
        setError(undefined);
      }

      // Ensure minimum loading time of 3 seconds
      const timeElapsed = new Date().getTime() - startTime.current;
      if (envConfig.env !== 'test' && timeElapsed < 3000) {
        setTimeout(() => setTestResult(result), 3000 - timeElapsed);
      } else {
        setTestResult(result);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [community.data, questId],
  );
  useEffect(() => {
    if (!isValidURL(fields?.endpoint)) return;
    run(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.endpoint, fields.apiKey]);

  useEffect(() => {
    const intervalId = setInterval(updateLoadingMessage, 1000);
    return () => clearInterval(intervalId);
  }, [updateLoadingMessage]);

  if (!startTime.current) return null;

  return testResult ? (
    <Alert
      title={t('title')}
      description={testResult.reason ? t(`error-codes.${testResult.reason}`) : t('success')}
      variant={testResult.success ? 'success' : 'error'}
    >
      {testResult?.data && (
        <div className="prose min-w-full mt-100 body-component-md font-mono flex-1">
          <div className="w-full flex items-center justify-between bg-quaternary h-component-sm px-component-sm rounded-t-component-sm">
            <p className="body-component-sm uppercase text-secondary">{t('response')}</p>
            {testResult.data?.status && (
              <p
                className={cn(
                  'body-component-sm font-mono',
                  testResult.data?.status >= 200 && testResult.data?.status < 300
                    ? 'text-success-primary'
                    : 'text-error-primary',
                )}
              >
                {testResult.data?.status}
              </p>
            )}
          </div>
          <pre className="m-0 rounded-t-none rounded-b-component-sm bg-tertiary w-full">
            <code className="text-secondary">{JSON.stringify(testResult.data?.data, null, 2)}</code>
          </pre>
        </div>
      )}

      <ConfigureTestUser fields={fields.identifications} />

      <Button
        leftIcon={<RotateCwLine />}
        onlyIcon
        size="sm"
        variant="ghost"
        mutedText
        onClick={() => run(fields)}
        className="absolute top-100 right-100"
      />
    </Alert>
  ) : (
    <Alert
      title={t('title')}
      description={t(TESTS[testIndex])}
      icon={<Loader2Line className="animate-spin w-alert-icon h-alert-icon" />}
    />
  );
};
