'use client';

import { getCommunityPath } from '@zealy/utils';

import { useCommunityV2 } from '#hooks/useCommunityV2';

export const useCommunityURL = () => {
  const { data } = useCommunityV2();

  return data ? getCommunityPath(data) : '';
};
